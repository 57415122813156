<template>
  <div>
    <standard-page title="Roles" :definition="$DEFINITIONS.admin.roles">
      <template v-slot:breadcrumb>
        <span>
          <router-link :to="{ name: 'admin-home'}">Admin</router-link>
        </span>
        <span>Role Permissions</span>
      </template>

      <template v-slot:buttons>

      </template>
      <template v-slot:content>
        <data-table :api="api" :columns="columns" @ready="onReady" list-key="permissions">
        </data-table>
      </template>
    </standard-page>

  </div>
</template>

<script>
import EventBus from "@/event-bus";
export default {
  name: "Roles",
  props: ["serviceid", "servicename", "roleid"],
  computed: {
    api() {
      return `${this.$accounts.defaults.baseURL}/accounts/permissions?service_id=${this.serviceid}`;
    },
  },
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          type: 'toggle', data: 'permission',
          render: data => {
            if (this.permissions.findIndex(permission => permission.permission == data) != -1) {
              return `<div class="disable az-toggle on"><span></span></div>`;
            } else {
              return `<div class="enable az-toggle"><span></span></div>`;
            }
          }
        }
      ],
      table: null,
      permissions: []
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      let result = await this.$accounts.get(`accounts/roles/${this.roleid}/permissions`);
      this.permissions = result.data.permissions;
      if (this.table) {
        this.table.ajax.reload();
      }
    },

    onReady(table) {
      this.table = table;
      let self = this;

      $(function () {
        $('#main-table tbody').on('click', '.enable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.addPermission(row);
          table.ajax.reload();
        });

        $('#main-table tbody').on('click', '.disable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.removePermission(row);
          table.ajax.reload();
        });

      });

    },
    async addPermission(row) {
      try {
        await this.$accounts.patch(`accounts/roles/${this.roleid}/permissions`, { permission: row.permission });
        this.permissions.push({
          permission: row.permission
        });
        this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e("Error adding permission", "Error");
      }

    },
    async removePermission(row) {
      try {
        await this.$accounts.delete(`accounts/roles/${this.roleid}/permissions`, { data: { permission: row.permission } });
        this.permissions.splice(this.permissions.findIndex(p => p.permission == row.permission), 1);
        this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e("Error removing permission", "Error");
      }
    },
  }
}
</script>

<style scoped>
</style>